import React, { useState, useEffect } from "react";
import {
  FaEye,
  FaHeart,
  FaMapMarkerAlt,
  FaUndo,
  FaComments,
  FaUserSecret,
} from "react-icons/fa";

const PremiumFeatures = {
  CHAT_WITH_MATCHING: { label: "Chat with Matching", icon: <FaComments /> },
  SEND_LIKE: { label: "Send Like", icon: <FaHeart className="text-red-500" /> },
  INCOGNITO_MODE: {
    label: "Incognito Mode",
    icon: <FaUserSecret className="text-gray-700" />,
  },
  SEE_LIKE: { label: "See Likes", icon: <FaEye /> },
  SET_PREFERRED_LOCATION: {
    label: "Set Preferred Location",
    icon: <FaMapMarkerAlt />,
  },
  RESET_POTENTIAL_MATCHES: {
    label: "Reset Potential Matches",
    icon: <FaUndo />,
  },
};

const PremiumFeaturePointMap = {
  CHAT_WITH_MATCHING: 20,
  SEND_LIKE: 5,
  SEE_LIKE: 3,
  SET_PREFERRED_LOCATION: 15,
  RESET_POTENTIAL_MATCHES: 30,
  INCOGNITO_MODE: 20,
};

// Discounts based on points thresholds
const getDiscountedPrice = (points) => {
  const basePrice = points / 5; // Base price calculation

  // Define discounts based on your specific needs
  if (points >= 6000) {
    return basePrice * 0.75; // Apply a 25% discount
  } else if (points >= 4000) {
    return basePrice * 0.75; // Apply a 25% discount
  } else if (points >= 3000) {
    return basePrice * 0.9; // Apply a 10% discount
  }

  return basePrice; // No discount for points below 3000
};

const Loading = () => (
  <div className="flex justify-center items-center h-64">
    <div className="animate-spin rounded-full h-32 w-32 border-b-2 border-gray-900"></div>
  </div>
);

const PaymentPackages = () => {
  const [loading, setLoading] = useState(false);
  const [alertMessage, setAlertMessage] = useState(null);
  const [userId, setUserId] = useState("");
  const [customPoints, setCustomPoints] = useState("");
  const [customPrice, setCustomPrice] = useState(0);

  useEffect(() => {
    const userId_ = new URL(window.location.href).pathname.split("/").pop();
    setUserId(userId_);
  }, []);

  const discountPackages = [
    { points: 1500, price: getDiscountedPrice(1500) },
    { points: 4000, price: getDiscountedPrice(4000) }, // Corrected
    { points: 6000, price: getDiscountedPrice(6000) }, // Corrected
  ];

  const handlePayment = async (amount, points) => {
    setLoading(true);
    try {
      const res = await fetch(
        `${process.env.REACT_APP_SERVER_URL_TEST}/subscription/initiatePointBasedSubscription`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${process.env.REACT_APP_AUTHORIZATION_TOKEN}`,
          },
          body: JSON.stringify({
            userId,
            amount,
            paymentMethod: "chapa",
            point: points,
          }),
        }
      );

      const responseJson = await res.json();
      if (res.status === 200) {
        window.location.href = responseJson.chapaPaymentUrl;
      } else {
        setAlertMessage({
          type: "error",
          message: responseJson.message || "Something went wrong",
        });
      }
    } catch (error) {
      setAlertMessage({
        type: "error",
        message: "Something went wrong. Please try again.",
      });
    } finally {
      setLoading(false);
    }
  };

  const renderPackages = () => {
    return discountPackages.map(({ points, price }) => (
      <div
        key={points}
        className="bg-white rounded-lg shadow-lg p-5 m-2 hover:shadow-2xl hover:scale-105 transform duration-300 border-l-4 border-customColor2 w-80"
      >
        <h3 className="text-center text-2xl font-semibold mb-3 text-gray-800">
          {points} Points
        </h3>
        <p className="text-center text-gray-600 mb-3">
          Price: {price.toFixed(2)} ETB
        </p>
        <button
          onClick={() => handlePayment(price, points)}
          className="bg-customColor2 text-white rounded-lg py-2 px-4 w-full transform hover:scale-105 hover:shadow-lg transition duration-200"
        >
          Buy {points} Points
        </button>
      </div>
    ));
  };

  const handleCustomPointsChange = (e) => {
    const points = parseInt(e.target.value, 10);
    const minPrice = 300;
    const price = getDiscountedPrice(points);

    setCustomPoints(points);
    setCustomPrice(price < minPrice ? minPrice : price);
  };

  const handleCustomPointsPurchase = () => {
    if (customPrice >= 300) {
      handlePayment(customPrice, customPoints);
    } else {
      setAlertMessage({
        type: "error",
        message: "The minimum purchase amount is 300 ETB.",
      });
    }
  };

  return (
    <div className="bg-gray-100 min-h-screen p-6">
      {loading && <Loading />}

      {alertMessage && (
        <div
          className={`${
            alertMessage.type === "success" ? "bg-green-500" : "bg-red-500"
          } text-white rounded-lg py-2 px-4 mb-4`}
        >
          {alertMessage.message}
        </div>
      )}

      <div className="container mx-auto">
        <div className="bg-white p-6 rounded-lg shadow-lg mb-8">
          <h2 className="text-3xl font-bold text-gray-800 mb-3">
            Welcome to Subscription
          </h2>
          <p className="text-gray-600 mb-4">
            Purchase points to unlock premium features in our app. Use points to
            send likes, chat without matches, and more!
          </p>
          <h3 className="text-xl font-semibold text-gray-800 mb-2">
            Premium Features and Costs
          </h3>
          <ul className="list-disc pl-5 text-gray-600">
            {Object.keys(PremiumFeaturePointMap).map((featureKey) => (
              <li key={featureKey} className="flex items-center my-2">
                <span className="mr-2">{PremiumFeatures[featureKey].icon}</span>
                {PremiumFeatures[featureKey].label} -{" "}
                <span className="font-semibold">
                  {PremiumFeaturePointMap[featureKey]} points
                </span>
              </li>
            ))}
          </ul>
        </div>

        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
          {renderPackages()}
        </div>

        <div className="bg-white rounded-lg shadow-lg p-5 m-2 w-80 hover:shadow-2xl hover:scale-105 transform duration-300 border-l-4 border-customColor2">
          <h3 className="text-center text-2xl font-semibold mb-3 text-gray-700">
            Custom Points
          </h3>
          <input
            type="number"
            value={customPoints}
            onChange={handleCustomPointsChange}
            placeholder="Enter points"
            className="border border-gray-300 rounded-lg p-2 w-full mb-3 focus:border-customColor2 focus:ring focus:ring-customColor2 focus:outline-none"
          />
          <p className="text-center text-gray-500 mb-1">
            Total Price: {customPrice.toFixed(2)} ETB
          </p>
          {customPrice < 300 && (
            <p className="text-red-500 text-center text-sm">
              The minimum purchase amount is 300 ETB.
            </p>
          )}
          <button
            onClick={handleCustomPointsPurchase}
            className="bg-customColor2 text-white rounded-lg py-2 px-4 w-full transform hover:scale-105 hover:shadow-lg transition duration-200"
          >
            Buy Custom Points
          </button>
        </div>
      </div>
    </div>
  );
};
export default PaymentPackages;
