import React, { useState, useEffect } from "react";
import { packages } from "../constants/constants";
function isEvenOrOdd(inputString) {
  try {
    if (!inputString) {
      return "Input string is empty.";
    }
    // Get the last character of the string
    let lastChar = inputString[inputString.length - 1];

    // Check if the last character is a digit
    if (!isNaN(lastChar)) {
      // Convert it to a number and check if it is even
      return Number(lastChar) % 2 === 0 ? "Even" : "Odd";
    } else {
      // Get ASCII value of the character and check if it is even
      let asciiValue = lastChar.charCodeAt(0);
      return asciiValue % 2 === 0 ? "Even" : "Odd";
    }
  } catch (err) {
    console.log(err);
    return "Even";
  }
}
const SubscriptionPlansDescending = ["Platinum", "Gold"];
const SubscriptionPlansAscending = ["Gold", "Platinum"];
const PaymentPackages = () => {
  const [loading, setLoading] = useState(false);
  const [alertMessage, setAlertMessage] = useState(null);
  const [userId, setUserId] = useState("");

  useEffect(() => {
    const userId_ = new URL(window.location.href).pathname.split("/").pop();
    setUserId(userId_);
  }, []);

  // monthy, biannually, annually
  const handlePayment = async (
    amount,
    planId,
    planType,
    duration,
    duration_id
  ) => {
    setLoading(true);
    await fetch(
      `${process.env.REACT_APP_SERVER_URL}/payment/intiateEthiopianPayment`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${process.env.REACT_APP_AUTHORIZATION_TOKEN}`,
        },
        body: JSON.stringify({
          userId: userId,
          amount: amount,
          planId: planId,
          planType: planType,
          duration_id: duration_id,
          duration: duration,
          paymentMethod: "chapa",
        }),
      }
    )
      .then(async (res) => {
        if (res.status === 200) {
          let responseJson = await res.json();
          // setAlertMessage({
          //   type: "success",
          //   message: responseJson.message,
          // });
          // navigate and take the user to the checkout url.
          window.location.href = responseJson.chapaPaymentUrl;
        } else if (res.status === 201) {
          let responseJson = await res.json();
          console.log(responseJson);
          setAlertMessage({
            type: "failed",
            message: responseJson.message,
          });
        } else {
          let responseJson = await res.json();
          console.log(responseJson);
          setAlertMessage({
            type: "error",
            message: "Something went wrong",
          });
        }
      })
      .catch((error) => {
        setAlertMessage({
          type: "error",
          message: "Something went wrong",
        });
      });
    setLoading(false);
  };

  const Loading = () => (
    <div className="flex justify-center items-center h-64">
      <div className="animate-spin rounded-full h-32 w-32 border-b-2 border-gray-900"></div>
    </div>
  );

  const renderPackages = (duration) =>
    (isEvenOrOdd(userId.toString()) === "Even"
      ? SubscriptionPlansAscending
      : SubscriptionPlansDescending
    ).map((type) => (
      <div key={type} className="md:w-1/3 p-2">
        <h3 className="text-center text-lg font-semibold mb-3 font-bold text-gray-400">
          {type}
        </h3>
        <div className="flex flex-wrap justify-center">
          {packages
            .filter(
              (packageItem) =>
                packageItem.title
                  .toLowerCase()
                  .includes(duration.toLowerCase()) &&
                packageItem.title.toLowerCase().includes(type.toLowerCase())
            )
            .map((packageItem, index) => (
              <div
                key={index}
                className="bg-white rounded-lg border border-gray-200 shadow-md hover:shadow-lg transition-shadow duration-300 ease-in-out flex flex-col m-2 mx-auto max-w-xs sm:max-w-sm md:max-w-md lg:w-80" //  style={{ maxWidth: '400px' }}
              >
                <div className="p-5 flex-grow">
                  <h5 className="text-lg font-bold tracking-tight text-gray-900 mb-3">
                    {packageItem.title}
                  </h5>
                  <p className="font-normal text-gray-700">
                    <span className="text-green-500 font-bold text-xl">
                      {packageItem.amount} Birr
                    </span>{" "}
                    / {packageItem.duration}
                  </p>
                  <ul className="mb-4 mt-3">
                    {packageItem.benefits.map((benefit, idx) => (
                      <li
                        key={idx}
                        className="text-gray-600 list-disc list-inside"
                      >
                        {benefit}
                      </li>
                    ))}
                  </ul>
                </div>
                <div className="mt-auto p-5">
                  <button
                    onClick={() =>
                      handlePayment(
                        packageItem.amount,
                        packageItem.planId,
                        packageItem.planType,
                        packageItem.duration_id,
                        packageItem.duration_id
                      )
                    }
                    className="bg-customColor text-white rounded-lg py-2 px-4 hover:bg-customColor2 w-full"
                  >
                    Pay to Upgrade
                  </button>
                </div>
              </div>
            ))}
        </div>
      </div>
    ));

  if (loading) {
    return <Loading />;
  }

  return (
    <div>
      {alertMessage && (
        <div
          className={`${
            alertMessage.type === "success" ? "bg-green-500" : "bg-red-500"
          } text-white rounded-lg py-2 px-4 mb-4`}
        >
          {alertMessage.message}
        </div>
      )}

      <div className="container mx-auto px-4">
        {/* Weekly Row */}
        <div className="mb-8">
          <h2 className="text-2xl font-bold my-4 text-white">
            Weekly Packages
          </h2>
          <div className="flex flex-wrap -mx-2">{renderPackages("Weekly")}</div>
        </div>

        {/* Monthly Row */}
        <div>
          <h2 className="text-2xl font-bold my-4 text-white">
            Monthly Packages
          </h2>
          <div className="flex flex-wrap -mx-2">
            {renderPackages("Monthly")}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PaymentPackages;
