import React from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import appStoreBadge from "../assets/app-store-badge.png";
import playStoreBadge from "../assets/play-store-badge.png";
import demoOne from "../assets/androidSplash.png";
import demoTwo from "../assets/applesplash.png";

function Home() {
  return (
    <main className="min-h-screen flex flex-col items-center justify-center p-6">
      <div className="flex flex-col md:flex-row items-center justify-center gap-8 mb-16 text-white">
        <div className="text-center">
          <h1 className="text-4xl font-bold mb-4">
            Find your soulmate on Gojo!
          </h1>
          <p className="text-lg mb-8">
            Connect with Ethiopians & Eritreans on Gojo Dating App.
          </p>
          <div className="flex flex-col sm:flex-row gap-4 items-center justify-center">
            <a
              href="https://play.google.com/store/apps/details?id=com.gojodating.gojo"
              className="order-1 sm:order-none"
            >
              <img
                src={playStoreBadge}
                alt="Get it on Google Play"
                className="sm:h-12 md:h-16 hover:scale-105 transition-transform"
              />
            </a>
            <a
              href="https://apps.apple.com/us/app/gojo-matching-world-youth-hub/id6473385940"
              className="order-2 sm:order-none"
            >
              <img
                src={appStoreBadge}
                alt="Download on the App Store"
                className="sm:h-12 md:h-16 hover:scale-105 transition-transform"
              />
            </a>
          </div>
        </div>
      </div>

      <div className="w-full flex flex-wrap justify-center items-center gap-4">
        <div className="sm:w-3/4 md:w-1/4 h-auto rounded-lg shadow-lg">
          <LazyLoadImage
            src={demoOne}
            alt="Demo One"
            className="w-full h-full"
            effect="blur"
            placeholder={
              <div style={{ height: "100%", backgroundColor: "#f3f3f3" }} />
            }
          />
        </div>
        <div className="sm:w-3/4 md:w-1/4 h-auto rounded-lg shadow-lg">
          <LazyLoadImage
            src={demoTwo}
            alt="Demo Two"
            className="w-full h-full"
            effect="blur"
            placeholder={
              <div style={{ height: "100%", backgroundColor: "#f3f3f3" }} />
            }
          />
        </div>
      </div>
    </main>
  );
}

export default Home;
